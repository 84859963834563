<template>
    <div style="text-align: left;background-color: #FFFFFF">
        <div class="pageCenter enterprise-container">
            <div v-show="+enterpriseEnterStatus === this.$oucy.enterpriseEnterStatus.WAITING_FOR_APPLY">
                <div style="width: 500px;margin: 0 auto;">
                    <el-divider><img src="../../../assets/icon/ent_crown.png" style=""><span style="font-size: 18px;font-weight: bold;color: #A76F4E;">入驻企业获得以下权益</span></el-divider>
                </div>
                <div class="vip-flag-container">
                    <img src="../../../assets/enterprise/enterprise_rzbs.png">
                    <img src="../../../assets/enterprise/enterprise_fbtj.png">
                    <img src="../../../assets/enterprise/enterprise_zsdp.png">
                    <img src="../../../assets/enterprise/enterprise_yhyx.png">
                </div>
                <div style="text-align: center;color: #cccccc;">您还没有企业入驻乐搜家</div>
                <div class="vip-button-container">
                    <el-button @click="go()" type="primary" :disabled="status!=0">{{status!=0?'你已认证买家身份':'申请入驻'}}</el-button>
                </div>
                <div style="text-align: center;color: #cccccc;">入驻流程</div>
                <div class="pageCenter" style="width: 600px;padding: 30px 0;">
                    <el-steps>
                        <el-step title="第一步" description="填写认证信息" icon="el-icon-edit"></el-step>
                        <el-step title="第二步" description="提交入驻申请" icon="el-icon-s-promotion"></el-step>
                        <el-step title="第三步" description="等待平台审核" icon="el-icon-s-order"></el-step>
                        <el-step title="第四步" description="认证成功" icon="el-icon-success"></el-step>
                    </el-steps>
                </div>
            </div>
            <div v-show="enterpriseEnterStatus === this.$oucy.enterpriseEnterStatus.APPLY_REFUSED">
                <msgResult :msg-type="2" title="企业入驻申请已被驳回" :desc="desc3" :buttons="refuseButtons"></msgResult>
            </div>
            <div v-show="
                enterpriseEnterStatus === this.$oucy.enterpriseEnterStatus.APPLYING_FOR_ENTERPRISE
                ||
                enterpriseEnterStatus === this.$oucy.enterpriseEnterStatus.APPLYING_FOR_AGENCY
                " style="text-align: center;">
                <msgResult :msg-type="4" title="企业入驻审核中" desc="尊敬的用户，你提交的企业入驻申请工作人员正在审核中，结果将通过短信及站内信进行通知！" :buttons="applyButtons"></msgResult>
            </div>
        </div>
    </div>
</template>
<script>
import oucy from "../../../util/oucyUtil";
import msgResult from "../../../components/MsgResult"
import { userAuth, realnameauthentication } from '@/service/index.js'
import {localSet, localGet, localDel} from "@/store/store"

export default {
    name: "Enterprise",
    components: {
        msgResult
    },
    data() {
        return {
            enterpriseEnterStatus: 0,
            refuseButtons: [{
                name: '再次申请',
                type: 'default',
                action: function() {
                    oucy.replace('/acc/enterpriseapply');
                }
            }],
            applyButtons: [{
                name: '个人中心',
                type: 'default',
                action: function() {
                    oucy.replace('/acc');
                }
            }],
            // 个人身份认证状态码
            status: 0,
            desc3:''
        }
    },
    mounted() {
        const me = this;
        // 查询客户端用户的所有企业

        userAuth.getEnterpriseByUser({}).then(res => {
            console.log('1111',res)
            if (res && res.length) {
                 // enterprise = res[0]
           
                let enterprise=localGet('enterprise')
                if(enterprise && enterprise.id && res[0] && res[0].id==enterprise.id){
                    this.getStatus(enterprise)
                }else{
                    localSet('enterprise',res[0])
                    this.getStatus(res[0])
                }

                // this.getStatus(enterprise)
            } else {
                localDel('enterprise')
                this.enterpriseEnterStatus = 0
            }
        })
        

    },
    methods: {
        getStatus(enterprise){
            const me = this;
            oucy.queryEnterApplyStatus({ enterpriseId: enterprise.id }).then(res => {
                me.enterpriseEnterStatus = res.status;
                if (res.status === oucy.enterpriseEnterStatus.APPLY_SUCCESS_ENTERPRISE
                ) {
                    oucy.replace('/enter/')
                }
                this.desc3= `尊敬的用户，你提交的企业入驻申请，经工作人员审核已驳回，原因：${res.auditInfo||'无'}。如有疑问可拨打我司客服热线！`
            });
            oucy.queryPersonalIdentifyStatus({ enterpriseId: enterprise.id }).then(res => {
                this.status = res
                console.log(res)
            })
        },
        go() {
            this.$oucy.replace('/acc/enterpriseapply')
            return
            realnameauthentication.isRealNameAuthentication().then(res => {
                if (res) {
                        this.$oucy.replace('/acc/enterpriseapply')
                } else {
                    this.$alert('请先进行实名认证', "提示", {
                        confirmButtonText: '确定',
                        callback: action => {
                           this.$oucy.go('/acc/realname', null)
                        }
                    });
                }
            })
        }
    }
}
</script>
<style scoped>
.enterprise-container {
    padding: 50px 0;
    min-height: 600px;
}

.vip-flag-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 50px 70px;
}

.vip-flag-container img {
    width: 180px;
    height: 140px;
}

.vip-button-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 0 50px 0;
}
</style>